// Google font
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Semi+Condensed:wght@400;500;600&display=swap');
$EncodeSansRegular: 'Encode Sans', sans-serif;
$EncodeSansSemiCondensed: 'Encode Sans Semi Condensed', sans-serif;

body {
    font-family: $EncodeSansRegular;
    padding: 0;
    margin: 0;
}
:root {
    --mainColor: rgba(98,179,144,1);
    --logoGreenDark: rgba(0, 55, 46, 1);
    --logoJBlueDark: rgba(46, 45, 127, 1);
    --logoGreenLight: rgba(98,179,144,1);
    --logoLightGreen: #62b390;
    --logoBlueLight: rgba(98,179,144,1);
    --logoBlue: rgba(5, 55, 125,1);
    --logoJBlueLight: rgba(149, 151, 189, 1);
    --AzureColor: rgb(246, 246, 246);
    --BackLightColor: rgba(98,179,144,.2);
    --BackLightMidColor: rgba(98,179,144,.7);
    --BackLightQColor: rgba(98,179,144,.96);
    --redColor: #f10909;
    --lightredColor: #fa6a6a;
    --greenColor: #2cf10963;
    --darkgreenColor: #1a940577;
    --NeonBlueColor: #3333FF;
    --VinilicasColor: #39468a;
    --lightgreenColor: #b0faa3;
    --ultraLightGreenColor: #dcf8d6;
    --WhiteLightGreenColor: #E0f8E0;
    --WhiteMLightGreenColor: rgba(199,228,215,.96);
    --whiteColor: #ffffff;
    --blackColor: #000000;
    --TraficoColor: #e1ab0f;
    --lightCyanColor: #5489bc;
    --ImpermeabilizantesColor: #8e3c32;
    --EspecializadosColor: #ab2719;
    --DeportivasColor: #418b3e;
    --Adhesivos-AditivosColor: #8f8f8f;
    --MaderasColor: #5b3e26;

}

@import 'https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400;500;600;700&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Encode+Sans+Semi+Condensed:wght@400;500;600&display=swap';

@import '@angular/material/prebuilt-themes/indigo-pink.css';

.jumbotron{
    padding-top: 0px;
    background: #ffffffbf;
}

.form-control, .input-group-text{
    border: 1px solid #0080ff;
}

table.table-hover tbody tr:hover {
    cursor: pointer;
}

html, body { height: 100%;}
body { margin: 0; font-family: 'Encode Sans', sans-serif;}


.menu-back{
    background-color: rgba(98,179,144,.2);
    :hover {
        //background-color: #bdd6cd;
        box-sizing:border-box;
        background-color:  var(--BackLightColor);
        border-radius: 4px;
        box-shadow: 0 1px 1px var(--logoGreenLight), 0 2px 2px var(--mainColor);    
        font: {
            color: rgba(240,240,240,.9);
            size: 1em;
            weight: 528;
        };   
      }
}

.menu-item-back{
    background-color: var(--WhiteMLightGreenColor) !important;

    :hover {
        font: {
            color: rgba(240,240,240,.9);
            size: 1em;
            weight: 600;
        };
        
        //margin-left: 1%; 
        background-color: var(--WhiteLightGreenColor);
        border-radius: 4px;
        box-shadow: 0 1px 1px var(--logoGreenLight), 0 2px 2px var(--mainColor);       

      }
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
	.navbar .nav-item .dropdown-menu{ display: none; }
	.navbar .nav-item:hover .nav-link{ margin-top:0; }
	.navbar .nav-item:hover .dropdown-menu{ display: block; }
	.navbar .nav-item .dropdown-menu{ margin-top:0; }
}	
/* ============ desktop view .end// ============ */
/* APP WathsApp */
.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}
/* APP WathsApp */